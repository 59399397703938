import Vue from 'vue';
import Vuex from 'vuex';
import user from './moudle/user';
import permission from './moudle/permission';
Vue.use(Vuex);
var store = new Vuex.Store({
  modules: {
    user: user,
    permission: permission
  }
});
export default store;