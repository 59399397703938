// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/ui-components/header_bar.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".layout-header[data-v-45285d06] {\n  position: fixed;\n  left: 0;\n  right: 0;\n  top: 0;\n  padding: 0 2.9vh;\n  height: 7.4vh;\n  line-height: 7.4vh;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 100% 7.4vh;\n  background-repeat: no-repeat;\n}\n.layout-header .title[data-v-45285d06] {\n  color: #cce5ff;\n  font-size: 3vh;\n  font-family: inherit;\n  font-weight: bold;\n  cursor: pointer;\n  margin-bottom: 1.3vh;\n}\n.layout-header .icon[data-v-45285d06] {\n  font-size: 2.4vh;\n  cursor: pointer;\n  margin-bottom: 1.3vh;\n}\n.layout-header .el-button.close[data-v-45285d06] {\n  background: #fff0;\n  border: 1px solid #dcdfe600;\n  color: #fff;\n  font-size: 2.4vh;\n}", ""]);
// Exports
module.exports = exports;
